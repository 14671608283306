/* eslint-disable no-inline-styles/no-inline-styles */
import React from 'react';

import { HtmlTextWrapper } from '@/core/features';
import { Link } from '@/core/features';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';

export type TooltipTextBoxProps = {
    extraPadding?: boolean;
    fontSize?: 'font-size-12' | 'font-size-14' | 'font-size-16';
    fontWeightTitle?: 'bold' | 'normal';
    hasFullWidth?: boolean;
    hasHyphen?: boolean;
    htmlText: string;
    htmlTitle?: null | string;
    linkAlignment?: 'center' | 'left' | 'right';
    linkText?: string;
    linkUrl?: string;
    withCloseButton?: boolean;
};

export default function TooltipTextBox({
    extraPadding = false,
    fontSize = 'font-size-14',
    fontWeightTitle = 'normal',
    hasFullWidth = false,
    hasHyphen = false,
    htmlText,
    htmlTitle,
    linkAlignment = 'left',
    linkText,
    linkUrl,
    withCloseButton = false,
}: TooltipTextBoxProps) {
    const { isDesktopView } = useDeviceoutput();
    return (
        <div
            className={'flex-column height-100 break-word border-radius-5'}
            style={{
                maxHeight: '80vh',
                minWidth: '320px',
                overflowY: 'auto',
                padding: withCloseButton || (extraPadding && isDesktopView) ? '20px' : '10px',
                width: hasFullWidth ? '100%' : withCloseButton ? '311px' : 'auto',
            }}
        >
            {htmlTitle && (
                <div className={`${fontSize} ${fontWeightTitle} margin-bottom-10`}>
                    <HtmlTextWrapper htmlText={htmlTitle} />
                </div>
            )}

            <div
                className={`${withCloseButton && fontSize === 'font-size-14' ? 'font-size-14' : 'font-size-12'}`}
                style={{
                    hyphens: hasHyphen ? 'auto' : 'none',
                }}
            >
                <HtmlTextWrapper htmlText={htmlText} />
            </div>

            {linkUrl && linkText && (
                <div
                    className={`link margin-top-10 font-size-12 ${linkAlignment === 'right' ? 'flex-end' : linkAlignment === 'center' ? 'flex-center' : ''}`}
                >
                    <Link
                        href={linkUrl}
                        linkType={'external'}
                        target={'_blank'}
                    >
                        {linkText}
                    </Link>
                </div>
            )}
        </div>
    );
}
